import request from './../request.js'

export function setmealList(params){
    return request({
        url: 'meal/setmeal/list.do?',
        method: 'post',
        data:params
    })
}
export function importExportRecord(params){
    return request({
        url: 'meal/setmeal/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}
export function accountList(params){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}
export function getMealList(params){
    return request({
        url: 'meal/vendorsetmeal/getMealList.do?',
        method: 'post',
        data:params
    })
}
export function getFirstName(params){  //获取所属类别
    return request({
        url: 'system/cardOperator/getFirstName.do?',
        method: 'post',
        data:params
    })
}
export function getSpeedModel(params){  //加载限速设置
    return request({
        url: 'system/cardOperator/getSpeedModel.do?',
        method: 'post',
        data:params
    })
}
export function getByCategory(params){  //获取子类别
    return request({
        url: 'system/cardOperator/getByCategory.do?',
        method: 'post',
        data:params
    })
}
export function setmealSave(params){  //添加
    return request({
        url: 'meal/setmeal/save.do?',
        method: 'post',
        data:params
    })
}
export function update(params){  //编辑
    return request({
        url: 'meal/setmeal/update.do?',
        method: 'post',
        data:params
    })
}
export function findById(params){  
    return request({
        url: 'meal/setmeal/findById.do?',
        method: 'post',
        data:params
    })
}

export function dcpdowndata(params){   //5 下行
    return request({
        url: 'meal/dcpdowndata/list.do?',
        method: 'post',
        data:params
    })
}
export function dcpupdata(params){   //5 上行
    return request({
        url: 'meal/dcpupdata/list.do?',
        method: 'post',
        data:params
    })
}
export function speeddata(params){   //8 限速
    return request({
        url: 'unicom2/speeddata/list.do?',
        method: 'post',
        data:params
    })
}
export function speeddata4(params){  //4
    return request({
        url: 'unicom/speeddata/list.do?',
        method: 'post',
        data:params
    })
}
export function speeddata_(params){   //other
    return request({
        url: 'card/speeddata/list.do?',
        method: 'post',
        data:params
    })
}
export function dcpSpeedSave(params){   // save 5
    return request({
        url: 'meal/setmeal/dcpSpeedSave.do?',
        method: 'post',
        data:params
    })
}
export function newLtSpeedSave(params){   // save 8
    return request({
        url: 'meal/setmeal/newLtSpeedSave.do?',
        method: 'post',
        data:params
    })
}
export function speedUnicom(params){   // save 4
    return request({
        url: 'meal/setmeal/speedUnicom.do?',
        method: 'post',
        data:params
    })
}
export function telecomSpeedSave(params){   // save other
    return request({
        url: 'meal/setmeal/telecomSpeedSave.do?',
        method: 'post',
        data:params
    })
}

//agentMeal.vue
export function agentList(params){ 
    return request({
        url: 'meal/agentsetmeal/agentList.do?',
        method: 'post',
        data:params
    })
}
export function setSellMoney(params){ 
    return request({
        url: 'meal/agentsetmeal/setSellMoney.do?',
        method: 'post',
        data:params
    })
}
export function dicQuery(params){ 
    return request({
        url: '/dict/qury.do?',
        method: 'post',
        data:params
    })
}
export function copyRecord(params){ 
    return request({
        url: '/meal/setmeal/copyAndAdd.do?',
        method: 'post',
        data:params
    })
}

export function exportAgentMealList(params){//agentMeal export
    return request({
        url: 'meal/agentsetmeal/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}




