import {
	NotFoundRouterMap
} from '@/router'
import router from '@/router'
import {getMenuList,logout,getCaptalStatus} from '@/api/login.js'
import {
	Layout
} from '@/layout'
import {getAgentType,getMsgStatus} from "@/api/agentManagement.js"


const permission = {
	state: {
		addRouters: [],
		refresh:true,
		title:'',
		currentTab:'',
		cachedRouterTabs:[],
		searchItem:{searchType:'',searchStr:''},
		role:'',
		agentTree:'',
		cachedViews: [],
		hasNotRead:false,
		hasNew:false,
		agentId:"",
		isCaptal:"",
		curType:"",
	},
	getters: {
		addRouters: state => state.addRouters, // 权限过滤路由
		isRefresh:state => state.refresh,
		title: state =>  state.title,
		currentTab: state =>  state.currentTab,
		cachedRouterTabs: state =>  state.cachedRouterTabs,
		searchItem: state =>  state.searchItem,
		currentRole:state => state.role,
		cachedViews: state => state.cachedViews,
		hasNotRead:state => state.hasNotRead,
		hasNew:state => state.hasNew,
		agentTree:state=>state.agentTree,
		currentAgentId:state=>state.agentId,
		isCaptal:state=>state.isCaptal,
		curType:state=>state.curType,
	},
	mutations: {
		SET_ADD_ROUTERS: (state, routers) => {
			state.addRouters = routers // 权限路由
		},
		SET_REFRESH: (state, refresh) => {
			state.refresh = refresh
		},
		SET_TITLE: (state, data) => {
			if(!!data.realname){
				state.title = data.realname
			}
			state.curType = data.type
			
		},
		SET_CACHED_ROUTER:(state, cachedRouterPath) => {
			if (state.currentTab != cachedRouterPath.current && !!cachedRouterPath.current) {
				state.currentTab = cachedRouterPath.current
			}
			state.cachedRouterTabs = cachedRouterPath.paths
		},
		SET_SEARCH_ITEM: (state, searchItem) => {
			state.searchItem = searchItem
		},

		SET_ROLE: (state,data) =>{
			state.role = data.agentType  // 代理商  vip代理商  管理员
			state.agentId  = data.agentId  
		},
		SET_AGENTTREE:(state,agentTree)=>{
            state.agentTree = agentTree 
		},

		// 添加缓存页面
		ADD_CACHED_VIEW: (state, view) => {
			if (state.cachedViews.includes(view.name)) return
			  state.cachedViews.push(view.name)
		},

		// 删除缓存页面
		DEL_CACHED_VIEW: (state, name) => {
			const index = state.cachedViews.indexOf(name)
			index > -1 && state.cachedViews.splice(index, 1)
		},

		// 删除所有缓存页面
		DEL_ALL_CACHED_VIEWS: state => {
			state.cachedViews = []
		},

		// 设置消息状态
		SET_MSG_STATUS:(state,data)=>{
			state.hasNotRead = data.hasNotRead
			state.hasNew = data.hasNew
		},
		// 设置是否是首都在线
		SET_CAPTAL_STATUS:(state,data)=>{
			state.isCaptal = data.dictValue // 1 首都在线 2 天翼云 
		
		}
	
	},
	actions: {
		getMenuList({commit}, res){
			return new Promise((resolve)=>{
				 getMenuList({}).then((data)=>{
					//  console.log(data)
					// if (!!data.curUser.realname) {
						
					// 	commit('SET_TITLE', data.curUser.realname)
					// }
					if (!!data.curUser) {
						
						commit('SET_TITLE', data.curUser)
					}
					
					if (!!data.resources) {
						let res = data.resources
						let accessedRouters = []
							res.forEach(item => {
								let route = {
									path:item.signHtml ? '/' + item.signHtml : '/to',
									// name:item.signHtml,
									meta: {
										id: item.id,
										title: item.title,
										icon: item.iconCls.indexOf('iconfont') != -1 ? item.iconCls : ('icon iconfont '+item.iconCls)
									},
									chargeFlag:item.chargeFlag,
									charged:item.charged,
									component: Layout,
								}
								
								let children = []
							
								if (!!item.children && item.children.length > 0) {
									item.children.forEach(subItem => {
										let htmlUrl = subItem.htmlUrl.trim()
										htmlUrl = htmlUrl.charAt(0) == '/' ? htmlUrl.substr(1):htmlUrl
										subItem.htmlUrl = htmlUrl
										let subChildren = {
											path: subItem.signHtml ? subItem.signHtml : 'doc',
											name:subItem.signHtml,
											meta: {
												id: subItem.id,
												title: subItem.title,
												icon: subItem.iconCls.indexOf('iconfont') != -1?subItem.iconCls :'icon iconfont '+subItem.iconCls
											},
											chargeFlag:subItem.chargeFlag,
									        charged:subItem.charged,
											component: (resolve) => require(['@/' + subItem.htmlUrl], resolve)
										}
										children.push(subChildren)
									})
								} else {
									item.htmlUrl = item.htmlUrl.trim().substr(1)
									let subChildren = {
										path: '',
										name:item.signHtml,
										meta: {
											id: -1,
											title: item.title
										},
										chargeFlag:item.chargeFlag,
									    charged:item.charged,
										component: (resolve) => require(['@/' + item.htmlUrl], resolve)
									}
									children.push(subChildren)
								}
								route.children = children
								accessedRouters.push(route)
								
							})
							
							commit('SET_ADD_ROUTERS', accessedRouters)
							router.addRoutes(accessedRouters)
							router.addRoutes(NotFoundRouterMap)

							getAgentType().then(rtd=>{
								commit("SET_ROLE", rtd)
								commit("SET_AGENTTREE",rtd.agentTree)
								resolve()
							})
					}
					
				})
			})	
		},


		getMsgStatus({commit}, res){
			return new Promise((resolve)=>{ 
				getMsgStatus().then((data)=>{
					commit('SET_MSG_STATUS', data)
					resolve()
				})
			})
		},

		getCaptalStatus({commit}, res){
			return new Promise((resolve)=>{ 
				getCaptalStatus().then((data)=>{
					console.log(data,189)
					commit('SET_CAPTAL_STATUS', data[0])
					resolve()
				})
			})
		},
		
		
		LogOut(){
			logout({}).then(res=>{
				router.replace('/',()=>{
					location.reload()
				})
			})
		}

	}
}

export default permission
