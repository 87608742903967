<template>
  <el-container style="height: 100vh" class="main-login">
    <el-header class="header">修改密码</el-header>
    <el-main class="main">
      <el-form
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="form.username"
            clearable
            class="inputWidth"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            show-password
            clearable
            autocomplete="off"
            class="inputWidth"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordCheck">
          <el-input
            v-model="form.passwordCheck"
            show-password
            clearable
            autocomplete="off"
            class="inputWidth"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="checkPhone">
          <el-input
            v-model="form.checkPhone"
            clearable
            class="inputWidth"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <el-input
            v-model="form.smsCode"
            clearable
            class="inputWidth"
          ></el-input>
          <el-button @click="getSmsCode" :disabled="disButton"
            >获取验证码</el-button
          >
          <div v-if="disButton">请{{ time }}秒后再试</div>
        </el-form-item>
        <el-form-item class="itemForm">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { getSmsCode, modifyPsw } from "@/api/login.js";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.passwordCheck !== "") {
          this.$refs.ruleForm.validateField("passwordCheck");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        password: "",
        passwordCheck: "",
        checkPhone: "",
        smsCode: "",
      },
      disButton: false,
      time: 120,
      timeInterval: "",
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        passwordCheck: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        checkPhone: [{ required: true, message: "手机号码不能为空" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  },
  methods: {
    getSmsCode() {
      let params = {
        phone: this.form.checkPhone,
        username: this.form.username,
      };
      getSmsCode(params).then((res) => {
        this.disButton = true;
        this.timeInterval = setInterval(() => {
          this.time--;
          console.log(this.time);
          if (this.time == 0) {
            this.disButton = false;
            clearInterval(this.timeInterval);
          }
        }, 1000);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          modifyPsw(this.form)
            .then((res) => {
              this.$alert("修改成功请重新登录", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.replace("/", () => {
                    location.reload();
                  });
                },
              });
            })
            .catch((res) => {
              console.log(res);
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
.main-login {
  background-color: #f6f8fb;
}
.header {
  background-color: #fff;
  padding: 0 34px;
  font-size: 25px;
  color: #007fe1;
  line-height: 60px;
}
.main {
  margin: 20px auto;
  width: 50vw;
}
.el-input.inputWidth {
  width: 250px;
}
.el-button.buttonStyle {
  margin-left: 20px;
}
.el-form-item__content.itemForm {
  margin-top: 20px;
}
</style>
