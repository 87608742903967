import request from './request.js'
export function merchantList(params) {
    return request({
        url: "/pay/merchant/list.do?",
        method: 'post',
        data: params
    })
}


export function deleteMerchant(params) {
    return request({
        url: "pay/merchant/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function setDefault(params) {
    return request({
        url: "/pay/merchant/setDefault.do?",
        method: 'post',
        data: params
    })
}

export function addMerchantNum(params) {
    return request({
        url: "pay/merchant/insert.do?",
        method: 'post',
        data: params
    })
}
export function uploadFile(params) {
    return request({
        url: "/pay/upload.do",
        method: 'post',
        data: params,
        timeout: 20000,
        headers: { 'Content-Type': 'multipart/form-data' }

    })
}



export function agentMerchantNumList(params) {
    return request({
        url: "/pay/fitting/list.do?",
        method: 'post',
        data: params
    })
}

export function addAgentMerchantNum(params) {
    return request({
        url: "/pay/fitting/insert.do?",
        method: 'post',
        data: params
    })
}

export function deleteAgentMerchantNum(params) {
    return request({
        url: "/pay/fitting/deleteById.do?",
        method: 'post',
        data: params
    })
}

export function officialList(params) {
    return request({
        url: "/pay/official/list.do?",
        method: 'post',
        data: params
    })
}

export function deleteOfficialList(params) {
    return request({
        url: "/pay/official/deleteById.do?",
        method: 'post',
        data: params
    })
}

export function addOfficialList(params) {
    return request({
        url: "/pay/official/insert.do?",
        method: 'post',
        data: params
    })
}


export function subscribeList(params) {
    return request({
        url: "/pay/subscribe/list.do?",
        method: 'post',
        data: params
    })
}

export function delSubscribeList(params) {
    return request({
        url: "/pay/subscribe/delAgent.do?",
        method: 'post',
        data: params
    })
}

export function getAllUnAssAgent(params) {
    return request({
        url: "/pay/subscribe/getAllUnAssAgent.do?",
        method: 'post',
        data: params
    })
}

export function assAgent(params) {
    return request({
        url: "/pay/subscribe/assAgent.do?",
        method: 'post',
        data: params
    })
}
export function rebateList(params) {
    return request({
        url: "/pay/rebate/list.do?",
        method: 'post',
        data: params
    })
}
export function agentList(params) {
    return request({
        url: "/pay/rebate/agentSelect.do?",
        method: 'post',
        data: params
    })
}

export function findById(params) {
    return request({
        url: "/pay/rebate/findById.do?",
        method: 'post',
        data: params
    })
}

export function changePayType(params) {
    return request({
        url: "/pay/rebate/changePayType.do?",
        method: 'post',
        data: params
    })
}


export function getRebateDetail(params) {
    return request({
        url: "/pay/log/list.do?",
        method: 'post',
        data: params
    })
}

// 下载凭证
export function downloadPingZ(params) {
    return request({
        url: "/pay/download.do?",
        method: 'get',
        params: params,
        responseType: 'blob'
    })
}

// 重试
export function wxRetry(params) {
    return request({
        url: "/pay/rebate/wxRetry.do?",
        method: 'post',
        data: params
    })
}


// 取消
export function wxCancel(params) {
    return request({
        url: "/pay/rebate/wxCancel.do?",
        method: 'post',
        data: params
    })
}


export function rebateEnsure(params) {
    return request({
        url: "/pay/rebate/ensure.do?",
        method: 'post',
        data: params
    })
}
export function download(params) {
    return request({
        url: "/pay/download.do?",
        method: 'post',
        data: params
    })
}

export function payExport(params) {
    return request({
        url: "/pay/rebate/exportSync.do?",
        method: 'post',
        data: params
    })
}



export function agentBandWx(params) {
    return request({
        url: "/agent/bindWx/agent/all.do?",
        method: 'post',
        data: params
    })
}

export function editMerchant(params) { //查询编辑字段值
    return request({
        url: "/pay/merchant/findById.do?",
        method: 'post',
        data: params
    })
}

export function editSaveMerchant(params) { //查询编辑字段值
    return request({
        url: "/pay/merchant/update.do?",
        method: 'post',
        data: params
    })
}

export function editOfficialList(params) { //公众号编辑
    return request({
        url: "/pay/official/edit.do?",
        method: 'post',
        data: params
    })
}

export function rechargeUrl(params) { //充值二维码
    return request({
        url: "/pay/official/rechargeUrl.do?",
   method: 'post',
        data: params
    })
}

export function hasPhoneCheck(params) { //是否验证手机
    return request({
        url: "/authCheck/hasPhoneCheck.do?",
        method: 'post',
        data: params
    })
}


export function sendSmsCode(params) { //发送验证码
    return request({
        url: "/authCheck/sendSmsCode.do?",
        method: 'post',
        data: params
    })
}

export function checkSmsCode(params) { //验证验证码
    return request({
        url: "/authCheck/checkSmsCode.do?",
        method: 'post',
        data: params
    })
}
