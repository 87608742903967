import {dicQuery,getFirstName} from "@/api/setMealManagment/setMeal.js"
const cardManagement = {
    state: {
        iccidOrPhone:'',
        status:'',
		type:'',
		iccidOrPhone_v:'',
		iccidForTFJ:'',
		// packageType:[],
		categorys:[],
		// defaultStrategyList:[],
		// mergeCode:[],
		// secondCategory_yd:[],
		// secondCategory_lt:[],
	},
	getters: {
		iccidOrPhone: state => state.iccidOrPhone,
		status: state => state.status,
		type: state => state.type,
		iccidOrPhone_v: state => state.iccidOrPhone_v,
		iccidForTFJ:state => state.iccidForTFJ,
		// packageType:state=>state.packageType,//套餐类型
		categorys:state=>state.categorys,//所属类别
		// defaultStrategyList:state=>state.defaultStrategyList,//虚值策略
		// mergeCode:state=>state.mergeCode,//合并码
		// secondCategory_yd:state=>state.secondCategory_yd, //二级运营商类别移动
		// secondCategory_lt:state=>state.secondCategory_lt, //二级运营商类别联通

	},
	mutations: {
		SET_ICCID_OR_PHONE: (state, iccidOrPhone) => {
			state.iccidOrPhone = iccidOrPhone
        },
        SET_STATUS: (state, status) => {
			state.status = status
        },
        SET_TYPE: (state, type) => {
			state.type = type
		},
		SET_ICCID_OR_PHONE_V: (state, iccidOrPhone_v) => {
			state.iccidOrPhone_v = iccidOrPhone_v
		},
		SET_ICCID_FOR_TFJ:(state,iccidForTFJ) =>{
			state.iccidForTFJ = iccidForTFJ
		},
		SET_PACKAGE_TYPE:(state,data)=>{
			// state.packageType = data.packageType
			// state.defaultStrategyList = data.defaultStrategy
			// state.mergeCode = data.MergeCode
			// state.secondCategory_yd = data.secondCaterogy_yd
			// state.secondCategory_lt = data.secondCaterogy_lt
			
			console.log(state.secondCategory_yd)

		},
		SET_CATEGORYS:(state,category)=>{
           state.categorys = category
		},
		
	},
	actions:{
		getPackageType({commit}, res){
			return new Promise((resolve)=>{
				      let params = {
                dictGroup:"packageType,defaultStrategy,MergeCode,secondCaterogy_lt,secondCaterogy_yd",
                type:"1"
            }
				dicQuery(params).then((data)=>{
					commit('SET_PACKAGE_TYPE', data)
					resolve()
				})
			})
		},
		getCategory({commit}, res){
			return new Promise((resolve)=>{
				getFirstName().then((res)=>{
					let categorys = []
					if(res.length>0){
						res.forEach((item) => {
							if(item.firstName){
								if ((item.firstName.indexOf("联通")!=-1||item.firstName.indexOf("电信")!=-1||item.firstName.indexOf("移动")!=-1)&&item.firstName.indexOf("中国")==-1){
									item.firstName = "中国"+item.firstName
								   }
								categorys.push({
									name:item.firstName,
									value:item.category
								}) 
							}
						})
						commit('SET_CATEGORYS', categorys)
						resolve()
					}
					})
	  })
		}

	}
}

export default cardManagement