<template>

	<el-container style="height: 100vh;" class="home">
		<el-aside width="auto">
			<left-menu :isCollapse="isCollapse"></left-menu>
		</el-aside>
		<el-container>
			<el-header>
				<top-menu></top-menu>
			</el-header>
			<el-main>
				<!-- <keep-alive exclude="singleOperationStrategy,virtualCardDetail"> -->
				 <keep-alive :include="cachedViews">
					<router-view></router-view>
				</keep-alive>
				<!-- <router-view></router-view> -->
			</el-main>
			<!-- <el-footer>
				<i class="el-icon-phone"></i>
				<span>
					<a href='javascript:;'>400-9668-255</a>
					(工作日9:00-18:00) |
				</span>
				<i class="el-icon-folder-opened"></i>
				<span><a href='javascript:;'>lisijia@sunshineiot.net</a> | </span>
				<a href='javascript:;'>沪ICP备19035241号-1</a>
			</el-footer> -->
		</el-container>
	</el-container>


</template>

<script>
	import TopMenu from './topMenu.vue';
	import LeftMenu from './leftMenu.vue';
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				isCollapse: false
			}
		},
		components: {
			TopMenu,
			LeftMenu,
		},
		computed:{
			cachedViews() {
				console.log(this.$store.getters.cachedViews)
				return this.$store.getters.cachedViews
			},
		},
		mounted() {

		}


	}
</script>
<style>
	.home .el-header{
		padding: 0 !important;
		background-color: #F8F8F8;
		height: 114px !important;
	}
	.home .el-aside {
		height: 100%;
		background-color: #252b3a;
	}

	.home .el-main {
		background-color: #f8f8f8;
	}
	.home .el-footer{
		height: 40px !important;
		line-height: 40px !important;
	}
	.home .el-footer a{
		color:#337ab7;
	}
</style>
