import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import permission from './permission.js'
import cardManagement from './cardManagement.js'
import dictGroup from './dictGroup.js'
export default new Vuex.Store({
    modules: {
        permission,
        cardManagement,
        dictGroup
    }
});