<template>
  <div class="main-content main-login clearfix">
    <div class="left clearfix left-content">
      <div class="left left-img-content">
        <div class="block-shadow"></div>
        <div class="dot-image-left">
          <img src="../../assets/dot-left.png" />
        </div>
      </div>
      <div class="left left-des-content">
 
        <div class="login-title" style="margin-top: 240px">
          万物互联，从连接开始
        </div>

      </div>
    </div>
    <div class="right right-content clearfix">
      <div class="right-back right" style="z-index: -1"></div>
      <div class="login-box">
        <h3 style="font-size: 24px">账号登录</h3>
        <div class="input-back">
          <input
            placeholder="账号名"
            v-model="username" @focus="warnMsg=''"
            @keypress.enter="login"  @input="isShow=false"
          />
        </div>
        <div class="input-back password">
          <input
            placeholder="密码"
            v-model="password"
            type="password" @focus="warnMsg=''"
            @keypress.enter="login"
          />
        </div>
         <div class="input-back1 password" v-if="isShow">
           <input
            placeholder="请输入验证码" 
            v-model="newCode"
            @keypress.enter="login"
            class="inputWidth"  @focus="warnMsg=''">
          <el-button @click="getSmsCode" :disabled="disButton" class='loginButton'
            >获取验证码</el-button
          >
         
          
        </div>

        <button class="login-button" @click="login">登录</button>
        <div class="mt20 clearfix">
          <div class="left">
            <input type="checkbox" style="position: relative; top: 2px" />
            <span style="color: #9b9b9b; font-size: 14px; margin-left: 5px"
              >记住密码</span
            >
          </div>
          <div class="right">
            <span class="forgetPSW" @click="forgetPsw">忘记密码</span>
          </div>
        </div>
        <div style="text-align: center">
          <i
            class="el-icon-warning-outline"
            style="color: red"
            v-if="warnMsg.length > 0"
          ></i>
          <span style="font-size: 13px; color: red; margin-left: 4px">{{
            warnMsg
          }}</span>
        </div>
         <div style="text-align: center;font-size: 13px; color: red; margin-left: 4px" v-if="disButton" >{{ time }}秒后能再次获取验证码</div>
      </div>
      <div class="right-dot">
        <img src="../../assets/dot-right.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { login,getNewCode } from "@/api/login.js";
import Loading from "@/utils/Loading.js";
import { getAgentType } from "@/api/agentManagement.js";
// import {messageBox,confirmBox} from '@/utils/common.js'
import {
	Notification,
	Message,
	MessageBox
} from 'element-ui'
export default {
  data() {
    return {
      username: "",
      password: "",
      warnMsg: "",
      newCode:"",
      isShow:false,
      disButton:false,
      time: 120,
      timeInterval: "",
    };
  },
   mounted() {
    this.$store.dispatch("getCaptalStatus")
    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  },
  methods: {
    login() {
      if (this.username == "" || this.password == "") {
        this.warnMsg = "不能为空";
        return;
      }
      if(this.isShow && this.newCode ==""){
          this.warnMsg = "验证码不能为空";
        return;
      }
      let params = {
        username: this.username,
        password: this.password,
        newCode:this.newCode,
      };
      Loading.show();
      login(params).then((res) => {
		  console.log(res)
      if( res.data == 1013 || res.data == 1014 ){
        MessageBox.alert(res.msg,'提示', {
				confirmButtonText: "确定",
				callback: (action) => {
					this.isShow  = true
				}
			  })
        return 
      }else{
       this.isShow  = false
       if(res){
        this.$store.dispatch("getDictGroups")
        this.getMenuList();
		  }
      }
		  
            
         })
        .catch(() => {
          this.username = "";
          this.password = "";
          this.newCode = ""
        });
    },
      getSmsCode() {
      let params = {
        username: this.username,
      };
      getNewCode(params).then((res) => {
        this.disButton = true;
        this.timeInterval = setInterval(() => {
          this.time--;
          console.log(this.time);
          if (this.time == 0) {
            this.disButton = false;
            clearInterval(this.timeInterval);
            this.time = 120
          }
        }, 1000);
      });
    },
    getMenuList() {
      this.$store.dispatch("getMenuList").then(() => {
        this.$store.commit("SET_REFRESH", false);
        getAgentType().then((res) => {
          if (res.agentType == 0) {
            this.$router.replace("/home").catch((err) => {
              console.log(err);
              this.$router.replace("/home");
            });
          } else {
            this.$router.replace("/agentHome").catch((err) => {
              console.log(err);
              this.$router.replace("/agentHome");
            });
          }
        });
      });
    },
    forgetPsw() {
      this.$router.replace("/modifyPsw").catch((err) => {
        console.log(err);
        this.$router.replace("/modifyPsw");
      });
    },
  },
};
</script>

<style>
.main-login {
  width: 100%;
  height: 100vh;
  background-color: #f6f8fb;
}
.left-content {
  height: 100%;
  width: 60%;
}
.left-img-content {
  margin-left: 45px;
  margin-top: 32px;
  position: relative;
}
.block-shadow {
  width: 152px;
  height: 330px;
  background-image: linear-gradient(180deg, #e7ebf4 0%, #f6f8fb 100%);
}
.dot-image-left {
  position: absolute;
  top: 36px;
  left: 103px;
}
.left-des-content {
  margin-left: 83px;
  height: 100%;
}
.login-title {
  margin-top: 110px;
  color: #1f1f1f;
  font-size: 36px;
}
.login-des {
  margin-top: 60px;
}
.login-des div span {
  color: #8b8f98;
  font-size: 15spx;
  padding: 10px 0;
}
.login-contact {
  position: absolute;
  bottom: 84px;
}
.login-contact div span {
  color: #727781;
  font-size: 13px;
}
.right-content {
  height: 100%;
  width: 40%;
}
.right-back {
  width: 400px;
  height: 90%;
  margin-top: 5%;
  background-color: #2468f2;
  box-sizing: border-box;
}
.login-box {
  width: 400px;
  min-height: 400px;
  background-color: #fff;
  position: absolute;
  right: 200px;
  top: calc((100% - 400px) / 2);
  box-shadow: -2px -2px 8px #e6e6e6;
  padding: 40px;
  box-sizing: border-box;
}
.input-back {
  border-radius: 5px;
  border: solid 1px #d7d7d7;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 40px;
}
.input-back1{
  height: 40px;
  box-sizing: border-box;
  margin-top: 40px;
}
.input-back.password {
  margin-top: 30px;
}
.input-back input {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.input-back1 input.inputWidth {
  width: 50%;
  height: 100%;
  background-color: transparent;
  border-radius: 5px;
  border: solid 1px #d7d7d7;
}
.input-back1 .loginButton{margin-left: 20px;display: inline-block;}
.input-back input:-internal-autofill-previewed,
.input-back input:-internal-autofill-selected {
  transition: background-color 5000s ease-out 0.5s;
}
.login-button {
  width: 100%;
  height: 40px;
  background-color: #007fe1;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
}
.login-button:hover {
  background-color: #008bf5;
}
.right-dot {
  position: absolute;
  right: 276px;
  top: calc((100% - 400px) / 2 + 440px);
}
.forgetPSW {
  color: #007fe1;
  font-size: 14px;
  margin-left: 5px;
}
</style>
