import request from './request.js'
export function trafficUsageMaList(params) {
    return request({
        url: "agentFlow/list.do?",
        method: 'post',
        data: params
    })
}
export function closeApi(params) {
    return request({
        url: "agentFlow/apiOpt.do?",
        method: 'post',
        data: params
    })
}

export function editAgent(params) {
    return request({
        url: "agentFlow/updateform.do?",
        method: 'post',
        data: params
    })
}

export function saveAgent(params) {
    return request({
        url: "agentFlow/update.do",
        method: 'post',
        data: params
    })
}

export function deleteAgent(params) {
    return request({
        url: "agentFlow/deletebyid.do?",
        method: 'post',
        data: params
    })
}
export function getAgentList(params) {
    return request({
        url: "agent/list4agent.do?",
        method: 'post',
        data: params
    })
}
export function saveAddAgent(params) {
    return request({
        url: "agentFlow/save.do?",
        method: 'post',
        data: params
    })
}
export function getAgentSwitchList(params) {
    return request({
        url: "agent/list4agent.do?",
        method: 'post',
        data: params
    })
}
export function getAgentStatus(params) {
    return request({
        url: "/agent/agentSwitch/list.do?",
        method: 'post',
        data: params
    })
}

export function changeSwitchStatus(params) {
    return request({
        url: "agent/agentSwitch/switchChange.do?",
        method: 'post',
        data: params
    })
}

export function balanceInquiryList(params) {
    return request({
        url: "agent/agentrecharge/list.do?",
        method: 'post',
        data: params
    })
}
export function rechargeLoglist(params) {
    return request({
        url: "agent/agentrechargelog/list.do?",
        method: 'post',
        data: params
    })
}


export function agentList(params) {
    return request({
        url: "agent/list.do?",
        method: 'post',
        data: params
    })
}


export function getAgentType(params) {
    return request({
        url: "agent/getLoginUser.do?",
        method: 'post',
        data: params
    })
}

// 修改用户信息
export function editAgentInfo(params) {
    return request({
        url: "agent/editAgent.do?",
        method: 'post',
        data: params
    })
}


export function deleteAgent1(params) {
    return request({
        url: "agent/deleteagent.do?",
        method: 'post',
        data: params
    })
}
export function editAgent1(params) {
    return request({
        url: "/agent/updateform.do?",
        method: 'post',
        data: params
    })
}
export function submitAgentChange(params) {
    return request({
        url: "/agent/update.do?",
        method: 'post',
        data: params
    })
}
export function accountManageList(params) {
    return request({
        url: "system/adminUser/list.do?",
        method: 'post',
        data: params
    })
}
export function saveAddAgentAccount(params) {
    return request({
        url: "system/adminUser/save.do?",
        method: 'post',
        data: params
    })
}

export function editAgentAccount(params) {
    return request({
        url: "/system/adminUser/updateForm.do?",
        method: 'post',
        data: params
    })
}

export function editAgentAccountSubmit(params) {
    return request({
        url: "/system/adminUser/update.do?",
        method: 'post',
        data: params
    })
}
export function editPassSubmit(params) {
    return request({
        url: "system/adminUser/updatePassword.do?",
        method: 'post',
        data: params
    })
}

export function addTocAgentSubmit(params) {
    return request({
        url: "agent/addAgent.do?",
        method: 'post',
        data: params
    })
}
export function addTobAgentSubmit(params) {
    return request({
        url: "/agent/saveTob.do?",
        method: 'post',
        data: params
    })
}

export function addPreAgentSubmit(params) {
    return request({
        url: "/agent/savePro.do?",
        method: 'post',
        data: params
    })
}


export function agentRechangeList(params) {
    return request({
        url: "/agent/agentrecharge/list.do?",
        method: 'post',
        data: params
    })
}
export function agentRechangeSubmit(params) {
    return request({
        url: "/agent/agentrecharge/addsave.do?",
        method: 'post',
        data: params
    })
}
export function agentResetPswSubmit(params) {
    return request({
        url: "/agent/agentrechargepassword/updatesave.do?",
        method: 'post',
        data: params
    })
}

export function changeBalance(params) {
    return request({
        url: "agent/agentrecharge/updateform.do?",
        method: 'post',
        data: params
    })
}

export function changeBalanceSubmit(params) {
    return request({
        url: "agent/agentrecharge/deduct.do?",
        method: 'post',
        data: params
    })
}

export function updateWarnMoney(params) {
    return request({
        url: "/agent/agentrecharge/updatesave.do?",
        method: 'post',
        data: params
    })
}
export function chargeLogDetailList(params) {
    return request({
        url: "/agent/agentrechargelog/list.do?",
        method: 'post',
        data: params
    })
}
export function agentTreeList(params) {
    return request({
        url: "/agent/getTree.do?",
        method: 'post',
        data: params
    })
}

export function agentrechargelogExport(params) {
    return request({
        url: "agent/agentrechargelog/export2excelSync.do?",
        method: 'post',
        data: params
    })
}

export function sendSmsCod(params) {
    return request({
        url: "agent/sendSmsCod.do?",
        method: 'post',
        data: params
    })
}

export function agentRebateCinfigList(params) {
    return request({
        url: "/agent/agentRebateCinfig/list.do?",
        method: 'post',
        data: params
    })
}

export function findByIdList(params) {
    return request({
        url: "/agent/agentRebateCinfig/findById.do?",
        method: 'post',
        data: params
    })
}

export function agentRebateCinfigUpdate(params) {
    return request({
        url: "/agent/agentRebateCinfig/update.do?",
        method: 'post',
        data: params
    })
}

export function agentListAll(params) {
    return request({
        // url: "/agent/list4agentAll.do?",
        url: "/agent/listForAdminAll.do?",
        method: 'post',
        data: params
    })
}

export function agentSKUList(params) {
    return request({
        url: "/agent/agentSKU/list.do?",
        method: 'post',
        data: params
    })
}

export function agentSKUfindById(params) {
    return request({
        url: "/agent/agentSKU/findById.do?",
        method: 'post',
        data: params
    })
}
export function accountList(params) {
    return request({
        url: "/system/cardOperator/getAccountListByAgent.do?",
        method: 'post',
        data: params
    })
}

export function geMealList(params) {
    return request({
        url: "/meal/setmeal/getAgentMealList.do?",
        method: 'post',
        data: params
    })
}

export function saveEditSKU(params) {
    return request({
        url: "/agent/agentSKU/edit.do?",
        method: 'post',
        data: params
    })
}

export function delAgentSKU(params) {
    return request({
        url: "/agent/agentSKU/del.do?",
        method: 'post',
        data: params
    })
}

export function saveAddSKU(params) {
    return request({
        url: "/agent/agentSKU/add.do?",
        method: 'post',
        data: params
    })
}

export function importAgentSKU(params) {
    return request({
        url: '/agent/agentSKU/importSync.do?',
        method: 'post',
        data: params,
        timeout: 20000,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export function getPayURL(params) {
    return request({
        url: "/pay/getPayUrl.do?",
        method: 'post',
        data: params
    })
}

export function alreadyPay(params) {
    return request({
        url: "/pay/alreadyPay.do?",
        method: 'post',
        data: params
    })
}

export function uploadPayOfflineUrl(params) {
    return request({
        url: "/commonUploadFile/upload.do?",
        method: 'post',
        data: params,
        timeout: 20000,
        headers: { 'Content-Type': 'multipart/form-data' }

    })
}

export function offlineTransfer(params) {
    return request({
        url: "/pay/offlineTransfer.do?",
        method: 'post',
        data: params
    })
}

export function agentRechargeList(params) {
    return request({
        url: "/selfRecharge/list.do?",
        method: 'post',
        data: params
    })
}

export function exportSelfRecharge(params) {
    return request({
        url: "/selfRecharge/exportAsync.do?",
        method: 'post',
        data: params
    })
}

export function checkSelfRecharge(params) {
    return request({
        url: "/selfRecharge/audit.do?",
        method: 'post',
        data: params
    })
}

export function getWechartUrl(params) {
    return request({
        url: "/agent/bindWx/getUrl.do?",
        method: 'post',
        data: params
    })
}


export function verifySmsCode(params) {
    return request({
        url: "/agent/verifySmsCod.do?",
        method: 'post',
        data: params
    })
}

// 通知状态
export function getMsgStatus(params) {
    return request({
        url: "/announce/agent/status.do?",
        method: 'post',
        data: params
    })
}

// 代理商绑定状态
export function getAgentMerchant(params) {
    return request({
        url: "/agentMerchant/get.do?",
        method: 'post',
        data: params
    })
}

// 提交绑定
export function applyAgentMerchant(params) {
    return request({
        url: "/agentMerchant/apply.do?",
        method: 'post',
        data: params
    })
}

// 商户号绑定审核
export function agentMerchantList(params) {
    return request({
        url: "/agentMerchant/list.do?",
        method: 'post',
        data: params
    })
}

// 商户号绑定审核
export function auditAgentMerchant(params) {
    return request({
        url: "/agentMerchant/audit.do?",
        method: 'post',
        data: params
    })
}

//解绑
export function unboundAgentMerchant(params) {
    return request({
        url: "/agentMerchant/unbound.do?",
        method: 'post',
        data: params
    })
}

export function getAccountList() {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
    })
}

export function getUserBlackList(params) {
    return request({
        url: '/userBlackList/list.do?',
        method: 'post',
        data: params
    })
}


export function exportBlackList(params) {
    return request({
        url: '/userBlackList/blackListExport.do?',
        method: 'post',
        data: params
    })
}
export function addBlackList(params) {
    return request({
        url: '/userBlackList/add.do?',
        method: 'post',
        data: params
    })
}

export function removeBlackList(params) {
    return request({
        url: '/userBlackList/del.do?',
        method: 'post',
        data: params
    })
}

export function agentRoleList(params) {
    return request({
        url: '/system/role/getRole.do?',
        method: 'post',
        data: params
    })
}


export function updagePhone(params) {
    return request({
        url: '/agent/updateCheckPhone.do?',
        method: 'post',
        data: params
    })
}


export function getAgencyReviewList(params) {//获取tob代理商
    return request({
        url: '/agentCheck/getPageList.do?',
        method: 'post',
        data: params
    })
}

export function deleteAgentReviewInfo(params) {//删除tob代理商
    return request({
        url: '/agent/delTobAgent.do?',
        method: 'post',
        data: params
    })
}
export function getPhoneCode(params) {//删除tob代理商验证码获取
    return request({
        url: '/agent/adminSmsCod.do?',
        method: 'post',
        data: params
    })
}

export function getBussinessInfo(params){//获取行业信息
    return request({
        url: '/agent/getBussinessInfo.do?',
        method: 'post',
        data: params
    })
}
export function getTobCompanyInfo(params){//获取工商信息
    return request({
        url: '/agent/getTobCompanyInfo.do?',
        method: 'post',
        data: params
    })
}
// export function getTobCompanyInfo(params){//获取工商信息
//     return request({
//         url: '/agent/getTobCompanyInfo.do?',
//         method: 'post',
//         data: params
//     })
// }
export function checkTobCompanyInfo(params){//工商信息审核
    return request({
        url: '/agent/checkTobCompanyInfo.do?',
        method: 'post',
        data: params
    })
}
export function checkTobCorperationInfo(params){//法人信息审核
    return request({
        url: '/agent/checkTobCorperationInfo.do?',
        method: 'post',
        data: params
    })
}

export function getSaleList(params){
    return request({
        url: '/batch/sale/allList.do?',
        method: 'post',
        data: params
    })
}
export function updateSale(params){
    return request({
        url: '/agentCheck/updateSale.do?',
        method: 'post',
        data: params
    })
}

export function getBussinessInfoOne(params){//获取行业信息
    return request({
        url: '/agent/getBussinessInfo.do?',
        method: 'post',
        data: params
    })
}

export function registerTob(params){//注册tob
    return request({
        url: '/agent/addTobAgent.do?',
        method: 'post',
        data: params
    })
}

export function modifyAccount(params){ //编辑账号
    return request({
        url: '/system/adminUser/updateAccountPhone.do?',
        method: 'post',
        data: params
    })
}

export function getRechargeUrl(params){ //编辑账号
    return request({
        url: '/agent/getRechargeUrl.do?',
        method: 'post',
        data: params
    })
}

export function configDetail(params){ //编辑代理商开关信息
    return request({
        url: '/agent/agentSwitch/configDetail.do?',
        method: 'post',
        data: params
    })
}
export function delChargeConfig(params){ //删除付费配置
    return request({
        url: '/agent/resource/deleteById.do?',
        method: 'post',
        data: params
    })
}
export function getResourceList(params){ //获取付费关联配置列表
    return request({
        url: '/agent/resource/list.do?',
        method: 'post',
        data: params
    })
}
export function getChargeResources(params){ //获取付费资源列表
    return request({
        url: '/system/resource/chargeResources.do?',
        method: 'post',
        data: params
    })
}

export function addChargeResources(params){ //增加付费资源关联
    return request({
        url: '/agent/resource/add.do?',
        method: 'post',
        data: params
    })
}

//设置提前停机

export function updateStopAheadDays(params){ 
    return request({
        url: '/agent/updateStopAheadDays.do?',
        method: 'post',
        data: params
    })
}

export function exportExcelAsync(params){ 
    return request({
        url: '/agent/exportExcelAsync.do?',
        method: 'post',
        data: params
    })
}




















