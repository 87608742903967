<template>
	<div class="left-menu" style="padding-top:20px">
		<!-- <div style="text-align: center;padding: 36px 0 30px;">
			<div style="padding-bottom: 5px;">
				<img src="../assets/logo_guangyao.png" style="width: 65px;height: 55px;" />
			</div>
			<span style="color: #8095a8;font-size: 11px;">{{title}}</span>
		</div> -->
		<el-menu :router="true" :default-active="$route.path" ref="menu" class="el-menu-vertical-demo"  mode="vertical" :collapse="isCollapse"  :unique-opened="true" @select="handleSelect">
			
			<div v-for="(router,index) in addRouters" :key="index">
				<el-menu-item v-if="router.children[0].meta.id.toString() == '-1' " :index="router.path ">
					<i :class="router.meta.icon"></i>
					<span slot="title">{{router.meta.title}}</span>
					<i class="el-icon-lock" v-if="router.chargeFlag&&!router.charged"></i>
				</el-menu-item>
				<el-submenu v-else :index="router.path">
					<template slot="title">
					    <i :class="router.meta.icon"></i>
					    <span slot="title">{{router.meta.title}}</span>
						<i class="el-icon-lock" v-if="router.chargeFlag&&!router.charged"></i>
					</template>
					<el-menu-item v-for="(subRouter,subIndex) in router.children" :key="subIndex" :index="router.path + '/' + subRouter.path" :class="{abortItem:subRouter.path == 'doc'}">
						<i :class="subRouter.meta.icon"></i>
						<span>{{subRouter.meta.title}}</span>
						<i class="el-icon-lock" style="color:gold;" v-if="subRouter.chargeFlag&&!subRouter.charged"></i>
					</el-menu-item>
				</el-submenu>
			</div>
		</el-menu>
	<!-- 	<el-scrollbar>
			
		</el-scrollbar> -->
	</div>
	

</template>

<script>
	import {
		mapGetters
	} from 'vuex'
export default {
        props:{
            isCollapse: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                
            };
        },
        computed: {
            ...mapGetters([
                'addRouters',
                'title'
            ]),
        },
        created() {
            // console.log(this.addRouters)
        },
        mounted() {
            
        },
        methods: {
            handleSelect(index,indexPath){
            // console.log(index,indexPath,70)
             
            }
        }
    }
</script>

<style>
	
	.left-menu .el-menu{
		background-color: #252b3a;
		border-right: 0;
		width: 180px;
	}
/* 	.left-menu .el-scrollbar,
	.left-menu .el-scrollbar__wrap{
		width: 100%;
	}
	.left-menu .el-scrollbar__wrap{
		
	} */
	.left-menu .el-menu .el-menu-item i,
	.left-menu .el-menu .el-submenu .el-submenu__title i,
	.left-menu .el-submenu .el-menu-item i{
		margin-right: 10px;
	}
	
	.left-menu .el-menu .el-menu-item i,
	.left-menu .el-menu .el-menu-item span,
	.left-menu .el-menu .el-submenu .el-submenu__title i,
	.left-menu .el-menu .el-submenu .el-submenu__title span{
		color: #fff;
		font-size: 14px;
	}
	
	.left-menu .el-submenu .el-menu-item i,
	.left-menu .el-submenu .el-menu-item span{
		color: #fff;
		font-size: 12px;
	}
	.left-menu .el-menu-item , 
	.left-menu .el-submenu__title{
		height: 44px;
		line-height: 44px;
	}
	.left-menu .el-submenu .el-menu-item{
		height: 36px;
		line-height: 36px;
		box-sizing: border-box;
	}
	.left-menu .el-menu .el-submenu.is-active,
	.left-menu .el-menu .el-menu-item.is-active {
		
		border-left: 4px solid #ff6b01;
	}
	.left-menu .el-menu .el-menu-item.is-active{
		background-color: #0d1018 !important;
	}
	
	.left-menu .el-menu .el-submenu .el-menu-item.is-active{
		border: none;
		
	}
	
	.left-menu .el-menu .el-menu-item:hover,
	.left-menu .el-menu .el-submenu .el-submenu__title:hover{
		background-color: #0d1018 !important;
	}
	.left-menu .el-submenu>.el-submenu__title .el-submenu__icon-arrow{
		transform: rotateZ(90deg);
	}
	.left-menu .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
		transform: rotateZ(0deg);
	}
	
	.left-menu .el-menu-item.abortItem:hover{
		background-color: #0d1018 !important;
	}
	.left-menu .el-menu-item.abortItem:focus{
		background-color: transparent !important;
	}

</style>