import {Layout} from '@/layout'

let routers = [
	{
		path: '/staticsManagement',
	      component:Layout,
	     children:[
				 {
					 path:"detail",
					 meta:{
						 isMenu:false,
						 	title: '查看详情',
					 },
					component: () => import('@/views/staticsManagement/PUSdetail.vue') 
				 }
			 ]
	  },
	  {
		path: '/staticsManagement',
	      component:Layout,
	     children:[
				 {
					 path:"daheMealDetail",
					 meta:{
						 isMenu:false,
						 	title: '详情',
					 },
					component: () => import('@/views/staticsManagement/daheMealDetail.vue') 
				 }
			 ]  
	  },
	  {
		path: '/staticsManagement',
	      component:Layout,
	     children:[
				 {
					 path:"daheDetail",
					 meta:{
						 isMenu:false,
						 	title: '详情',
					 },
					component: () => import('@/views/staticsManagement/daheDetail.vue') 
				 }
			 ]  
	  },
		{
			path: '/agentManagement',
		      component:Layout,
		     children:[
					 {
						 path:"rechageDetail",
						 meta:{
							 isMenu:false,
							 	title: '充值日志详情',
						 },
						component: () => import('@/views/agentManagement/rechargeDetail.vue') 
					 }
				 ]
		  },
		  {
			path: '/agentManagement',
		      component:Layout,
		     children:[
					 {
						 path:"allRechageDetail",
						 meta:{
							 isMenu:false,
							 	title: '充值日志详情',
						 },
						component: () => import('@/views/agentManagement/allRechargeDetail.vue') 
					 }
				 ]
		  },
			{
				path: '/agentManagement',
			      component:Layout,
			     children:[
						 {
							 path:"accountManagement",
							 meta:{
								 isMenu:false,
								 	title: '代理商账号管理',
							 },
							component: () => import('@/views/agentManagement/agentAccountManagement.vue') 
						 }
					 ]
			  },
				{
					path: '/agentManagement',
				      component:Layout,
				     children:[
							 {
								 path:"chargeLogDetail",
								 meta:{
									 isMenu:false,
									 	title: '充值日志详情',
								 },
								component: () => import('@/views/agentManagement/chargeLogDetail.vue') 
							 }
						 ]
				  },

				  {
					path: '/agentManagement',
				    component:Layout,
				     children:[
							 {
								 path:"payAuto",
								 meta:{
									isMenu:true,
									title: '自动充值中心',
								 },
								component: () => import('@/views/agentManagement/payAuto.vue') 
							 }
						 ]
				  },

				  {
					path: '/agentManagement',
				    component:Layout,
				     children:[
							 {
								 path:"payOffline",
								 meta:{
									isMenu:true,
									title: '线下充值',
								 },
								component: () => import('@/views/agentManagement/payOffline.vue') 
							 }
						 ]
				  },
				  {
					path: '/agentManagement',
				    component:Layout,
				     children:[
							 {
								 path:"InvoiceRequest",
								 meta:{
									isMenu:true,
									title: '发票申请',
								 },
								component: () => import('@/views/agentManagement/InvoiceRequest.vue') 
							 }
						 ]
				  },
				
					{
						path: '/packageRecharge',
					      component:Layout,
					     children:[
								 {
									 path:"orderRecord",
									 meta:{
										 isMenu:false,
										 	title: '充值日志详情',
									 },
									component: () => import('@/views/packageRecharge/orderRecord.vue') 
								 }
							 ]
					  },
						{
							path: '/home',
						      component:Layout,
						     children:[
									 {
										 path:"moreStatics",
										 meta:{
											 isMenu:true,
											 	title: '更多统计',
										 },
										component: () => import('@/views/home/moreSatics.vue') 
									 }
								 ]
						  },{
							path: '/home',
						      component:Layout,
						     children:[
									 {
										 path:"statics_pool_more",
										 meta:{
											 isMenu:true,
											 	title: '更多流量统计',
										 },
										component: () => import('@/views/home/poolMore.vue') 
									 }
								 ]
						  },
							{
							path: '/home',
							    component:Layout,
							   children:[
									 {
										 path:"cardDayFlow",
										 meta:{
											 isMenu:true,
											 	title: '网卡每日流量',
										 },
										component: () => import('@/views/home/cardDayFlow.vue') 
									 }
								 ]
							},
							{
								path: '/systemManagement',
									component:Layout,
								   children:[
										 {
											 path:"dictionaryDetail",
											 meta:{
												 isMenu:false,
											     title: '字典数据',
											 },
											component: () => import('@/views/systemManagement/dictionaryDetail.vue') 
										 }
									 ]
								},
								{
									path: '/purchaseCard',
										component:Layout,
									   children:[
											 {
												 path:"orderDetails",
												 meta:{
													 isMenu:false,
													 title: '字典数据',
												 },
												component: () => import('@/views/purchaseCard/orderDetails.vue') 
											 }
										 ]
									},
									{
										path: '/mappingManage',
											component:Layout,
										   children:[
												 {
													 path:"mappingList",
													 meta:{
														 isMenu:false,
														 title: '映射管理',
													 },
													component: () => import('@/views/configurationManagement/mappingList.vue') 
												 }
											 ]
										},
										{
											path: '/trafficPoolHistoryUsage',
												component:Layout,
											   children:[
													 {
														 path:"trafficPoolDetail",
														 meta:{
															 isMenu:false,
															 title: '映射管理',
														 },
														component: () => import('@/views/trafficPoolManagement/trafficPoolDetail.vue') 
													 }
												 ]
											},
											{
												path: '/shopping',
												component:Layout,
												children: [{
														path: 'startShopping',
														meta: {
														  isMenu:false,
															title: '开始选购',
														},
														component: () => import('@/views/onlinePurchase/startShopping.vue')
													},
													
												]
											  },
											  {
												path: '/shopping',
												component:Layout,
												children: [{
														path: 'shoppingCart',
														meta: {
														  isMenu:false,
															title: '购物车',
														},
														component: () => import('@/views/onlinePurchase/shoppingCartone.vue')
													},
													
												]
											  },
]
export default routers

