import request from './request.js'
export function listSim(params) {
    return request({
        url: 'statistics/enterprise/listSim.do?',
        method: 'post',
        data: params
    })
}
export function ListDayFlow(params) {
    return request({
        url: 'statistics/enterprise/ListDayFlow.do?',
        method: 'post',
        data: params
    })
}
export function ListDaySms(params) {
    return request({
        url: 'statistics/enterprise/ListDaySms.do?',
        method: 'post',
        data: params
    })
}
export function ListTopFlow(params) {
    return request({
        url: 'statistics/enterprise/ListTop.do?',
        method: 'post',
        data: params
    })
}
export function ListTopSMS(params) {
    return request({
        url: 'statistics/enterprise/ListTopSms.do?',
        method: 'post',
        data: params
    })
}
export function tobTopic(params) {
    return request({
        url: 'statistics/enterprise/tobTopic.do',
        method: 'post',
        data: params
    })
}
export function ListMonthFlow(params) {
    return request({
        url: 'statistics/enterprise/ListMonthFlow.do?',
        method: 'post',
        data: params
    })
}
export function ListMonthSms(params) {
    return request({
        url: 'statistics/enterprise/ListMonthSms.do?',
        method: 'post',
        data: params
    })
}
export function exportTemplate(params){
  return request({
    url: 'download/downloadTemp.do?',
    method: 'get',
    params:params,
	responseType: 'blob'
  })
}

export function bindPersonalUserByBatch(params){
  return request({
    url: '/area/device/bindPersonalUserByBatch',
    method: 'post',
    data:params,
	timeout:20000,
	headers:{'Content-Type': 'multipart/form-data'}
  })
}


export function listSimGroup(params) {
    return request({
        url: 'statistics/enterprise/listChargeSim.do?',
        method: 'post',
        data: params
    })
}
export function ListDayFlowGroup(params) {
    return request({
        url: 'statistics/enterprise/ListDayFlowGroup.do?',
        method: 'post',
        data: params
    })
}
export function ListDaySmsGroup(params) {
    return request({
        url: 'statistics/enterprise/ListDaySmsGroup.do?',
        method: 'post',
        data: params
    })
}
export function ListTopFlowGroup(params) {
    return request({
        url: 'statistics/enterprise/ListTopGroup.do?',
        method: 'post',
        data: params
    })
}
export function ListTopSMSGroup(params) {
    return request({
        url: 'statistics/enterprise/ListTopSmsGroup.do?',
        method: 'post',
        data: params
    })
}
export function exportMonthFlow(params){
  return request({
    url: 'statistics/enterprise/exportMonthCard.do?',
    method: 'post',
    params:params,
	
  })
}
export function exportMonthSms(params){
  return request({
    url: 'statistics/enterprise/exportMonthSms.do?',
    method: 'post',
    params:params,
	
  })
}

